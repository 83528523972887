import React from "react";
import Navbar from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import Benefits from "../components/landingpage/Benefits";
import Services from "../components/landingpage/Services";
import KnownFrom from "../components/landingpage/KnownFrom";
import Description from "../components/landingpage/Description";
import Hero from "../components/landingpage/Hero";
import Seo from "../components/SEO/SEO";
import ProcessDescription from "../components/landingpage/ProcessDescription";
import Donate from "../components/landingpage/Donate";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import "../index.css";
import { getPrices } from "../api";

function Index() {
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  React.useEffect(() => {
    getPrices();
  });
  return (
    <>
      <Seo />
      <Navbar />
      <Hero />
      {/*  <Banner />  disabled wallbox*/}
      <KnownFrom />
      <Benefits />
      <Services />
      <ProcessDescription />
      <Description />
      <Donate />
      <Footer />
    </>
  );
}

export default Index;
