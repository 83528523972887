import React from "react";
export const N1Icon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      x="0px"
      y="0px"
      height="85"
      width="85"
      className={className}
    >
      <title>005 - Electric Van</title>
      <path d="M13,50a3,3,0,1,0,3,3A3,3,0,0,0,13,50Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,13,54Z"></path>
      <path d="M48,50a3,3,0,1,0,3,3A3,3,0,0,0,48,50Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,48,54Z"></path>
      <path d="M57,8a3,3,0,0,0,0-6H50a2,2,0,0,0-2-2H46a2,2,0,0,0-2,2H41a7,7,0,0,0-6.92,6H19A11.013,11.013,0,0,0,8,19v4H5a3,3,0,0,0-3,3V47a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2H6.08a6.991,6.991,0,0,0,13.84,0H41.08a6.991,6.991,0,0,0,13.84,0H57a3,3,0,0,0,3-3V41.819a6.014,6.014,0,0,0-4.422-5.789l-3.248-.885a11.039,11.039,0,0,1-6-4.143l-3.424-4.708A8.024,8.024,0,0,0,36.436,23H10V19a9.01,9.01,0,0,1,9-9H34.08A7,7,0,0,0,41,16h3a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2h7a3,3,0,0,0,0-6H50V8ZM13,58a5,5,0,1,1,5-5A5.006,5.006,0,0,1,13,58Zm35,0a5,5,0,1,1,5-5A5.006,5.006,0,0,1,48,58Zm9.905-17H55a1,1,0,0,1-1-1V37.673l1.052.287A4.006,4.006,0,0,1,57.905,41ZM52,37.128V40a3,3,0,0,0,3,3h3v4H56a1,1,0,0,0,0,2h2v2a1,1,0,0,1-1,1H54.92a6.991,6.991,0,0,0-13.84,0H19.92A6.991,6.991,0,0,0,6.08,52H2V49H5a1,1,0,0,0,0-2H4V26a1,1,0,0,1,1-1H29v8a3,3,0,0,0,3,3H49.129a12.884,12.884,0,0,0,2.676,1.074ZM41.288,27.471l3.424,4.707A12.939,12.939,0,0,0,46.331,34H32a1,1,0,0,1-1-1V25h5.436A6.019,6.019,0,0,1,41.288,27.471ZM57,4a1,1,0,0,1,0,2H50V4ZM48,16H46V11a1,1,0,0,0-2,0v3H41A5,5,0,0,1,41,4h3V7a1,1,0,0,0,2,0V2h2Zm9-4a1,1,0,0,1,0,2H50V12Z"></path>
      <path d="M39,47H22a1,1,0,0,0,0,2H39a1,1,0,0,0,0-2Z"></path>
      <path d="M33.485,28.857l5,3a1,1,0,0,0,1.03-1.714l-5-3a1,1,0,1,0-1.03,1.714Z"></path>
    </svg>
  );
};
