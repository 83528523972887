import React from "react";
export const BusIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      x="0px"
      y="0px"
      height="85"
      width="85"
      className={className}
    >
      <title>009 - Electric Bus</title>
      <path d="M15,50a3,3,0,1,0,3,3A3,3,0,0,0,15,50Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,15,54Z"></path>
      <path d="M47,50a3,3,0,1,0,3,3A3,3,0,0,0,47,50Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,47,54Z"></path>
      <path d="M4,28v8a4,4,0,0,0,4,4H42a2,2,0,0,0,2-2V28a2,2,0,0,0-2-2H6A2,2,0,0,0,4,28Zm2,8V28h7V38H8A2,2,0,0,1,6,36Zm21,2H22V28h5Zm2-10h5V38H29ZM20,38H15V28h5Zm22,0H36V28h6Z"></path>
      <path d="M57,8a3,3,0,0,0,0-6H50a2,2,0,0,0-2-2H46a2,2,0,0,0-2,2H41a7,7,0,0,0-6.92,6H17a9.01,9.01,0,0,0-9,9v5H3a3,3,0,0,0-3,3V47a6.957,6.957,0,0,0,.427,2.384l0,.008A7.006,7.006,0,0,0,7,54H8.08a6.991,6.991,0,0,0,13.84,0H40.08a6.991,6.991,0,0,0,13.84,0H57a3,3,0,0,0,3-3V29a6.957,6.957,0,0,0-.427-2.384l0-.008A7,7,0,0,0,53,22H10V17a7.008,7.008,0,0,1,7-7H34.08A7,7,0,0,0,41,16h3a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2h7a3,3,0,0,0,0-6H50V8ZM15,58a5,5,0,1,1,5-5A5.006,5.006,0,0,1,15,58Zm32,0a5,5,0,1,1,5-5A5.006,5.006,0,0,1,47,58ZM58,29V42H53a5.006,5.006,0,0,1-5-5V29a1,1,0,0,1,1-1h8.9A5,5,0,0,1,58,29Zm-1.026-3H49a3,3,0,0,0-3,3v8a7.009,7.009,0,0,0,7,7h5v4H56a1,1,0,0,0,0,2h2v1a1,1,0,0,1-1,1H53.92a6.991,6.991,0,0,0-13.84,0H21.92A6.991,6.991,0,0,0,8.08,52H7a4.977,4.977,0,0,1-3.974-2H6a1,1,0,0,0,0-2H2.1A5,5,0,0,1,2,47V25a1,1,0,0,1,1-1H53A4.977,4.977,0,0,1,56.974,26ZM57,4a1,1,0,0,1,0,2H50V4ZM48,16H46V11a1,1,0,0,0-2,0v3H41A5,5,0,0,1,41,4h3V7a1,1,0,0,0,2,0V2h2Zm9-4a1,1,0,0,1,0,2H50V12Z"></path>
      <path d="M38,48H24a1,1,0,0,0,0,2H38a1,1,0,0,0,0-2Z"></path>
    </svg>
  );
};
