import React from "react";

const HeroBanner = () => {
  return (
    <div className="bg-orange-400 px-8 py-2.5 sm:px-3.5">
      <div className="mt-24 mb-4 flex items-center justify-center">
        <p className="text-base text-white leading-6 text-center">
          Unser{" "}
          <a
            className="underline hover:text-blue-200"
            href="https://www.gutscheine-thgquoten.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gutscheinportal
          </a>{" "}
          ist gestartet! Jetzt THG-Quote maximieren & 100 € Gutschein sichern!{" "}
          <a
            className="underline hover:text-blue-200"
            href="https://www.gutscheine-thgquoten.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mehr Details
          </a>
        </p>
      </div>
    </div>
  );
};

export default HeroBanner;
