import React from "react";

export const N23Icon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      x="0px"
      y="0px"
      height="85"
      width="85"
      className={className}
    >
      <title>007 - Electric Lorry</title>
      <path d="M13,50a3,3,0,1,0,3,3A3,3,0,0,0,13,50Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,13,54Z"></path>
      <path d="M48,50a3,3,0,1,0,3,3A3,3,0,0,0,48,50Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,48,54Z"></path>
      <path d="M44,36h8a2,2,0,0,0,2-2V32.071a1.986,1.986,0,0,0-.586-1.414l-5.071-5.071A2.011,2.011,0,0,0,46.929,25H44a2,2,0,0,0-2,2v7A2,2,0,0,0,44,36Zm0-9h2.929L52,32.071V34l-8,0Z"></path>
      <path d="M57,8a3,3,0,0,0,0-6H50a2,2,0,0,0-2-2H46a2,2,0,0,0-2,2H41a7,7,0,0,0-6.92,6H17a9.01,9.01,0,0,0-9,9v3H4.939A2.929,2.929,0,0,0,2,22.913V42a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2H6.08a7,7,0,1,0,13.84,0H41.08a7,7,0,1,0,13.84,0H58a2,2,0,0,0,2-2V44a2,2,0,0,0-2-2V31.242a3.974,3.974,0,0,0-1.172-2.828l-6.242-6.242A4.022,4.022,0,0,0,47.758,21H41a3,3,0,0,0-3,3V34H36V22.913A2.929,2.929,0,0,0,33.061,20H10V17a7.008,7.008,0,0,1,7-7H34.08A7,7,0,0,0,41,16h3a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2h7a3,3,0,0,0,0-6H50V8ZM13,58a5,5,0,1,1,5-5A5.006,5.006,0,0,1,13,58Zm35,0a5,5,0,1,1,5-5A5.006,5.006,0,0,1,48,58Zm10-8H54.315a6.985,6.985,0,0,0-12.63,0H19.315a6.985,6.985,0,0,0-12.63,0H2V44H58ZM40,24a1,1,0,0,1,1-1h6.758a2.011,2.011,0,0,1,1.414.586l6.242,6.242A1.988,1.988,0,0,1,56,31.242V42H40V24ZM38,36v6H36V36ZM34,22.913V42H4V22.913A.928.928,0,0,1,4.939,22H33.061A.928.928,0,0,1,34,22.913ZM57,4a1,1,0,0,1,0,2H50V4ZM48,16H46V11a1,1,0,0,0-2,0v3H41A5,5,0,0,1,41,4h3V7a1,1,0,0,0,2,0V2h2Zm9-4a1,1,0,0,1,0,2H50V12Z"></path>
      <path d="M48,38H46a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z"></path>
    </svg>
  );
};
